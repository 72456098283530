import React, { useCallback, useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { AiOutlineCheckSquare } from 'react-icons/ai';

import { Button, Flex, Radio } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useValidation } from '../../hooks/valid';
import { useToast } from '../../hooks/toast';
import useInterval from '../../hooks/useInterval';

import api from '../../services/api';

import { Container, Information } from './styles';
import Template from '../Template';

interface OpcaoVoto {
  id: string;
  label: string;
}

interface PerguntaAssembleia {
  id: number;
  pergunta: string;
  opcoes: OpcaoVoto[];
}

interface AssembleiaData {
  id: string;
  titulo: string;
}

function Validation() {
  const [perguntas, setPerguntas] = useState<PerguntaAssembleia[]>(
    [] as PerguntaAssembleia[],
  );

  const [assembleia, setAssembleia] = useState<AssembleiaData | null>(null);
  const history = useHistory();
  const delay = 1000;
  const [count, setCount] = useState(60);

  const { empregado, token, logoutVote } = useValidation();
  const { addToast } = useToast();

  const endSession = useCallback(() => {
    addToast({
      title: 'Sessão encerrada!',
      description: 'Tempo máximo para voto excedido!',
      type: 'error',
    });
    history.push('/assembleia/validation');
    logoutVote();
  }, [addToast, logoutVote, history]);

  useInterval(() => {
    if (count === 0) {
      endSession();
      history.push('/');
    }
    setCount(count - 1);
  }, delay);

  const form = useForm({
    initialValues: {
      votos: [{ voto: '' }],
    },
  });

  const radioGroupFields = perguntas.map((pergunta) => {
    const perguntaField = `pergunta_${pergunta.id}`;

    return (
      <Flex>
        <Radio.Group
          label={pergunta.pergunta}
          key={perguntaField}
          name={perguntaField}
          required
          {...form.getInputProps(`votos.${pergunta.id - 1}.voto`)}
          offset={2}
        >
          {pergunta.opcoes.map((opt) => (
            <Radio key={opt.id} value={opt.id} label={opt.label} />
          ))}
        </Radio.Group>
      </Flex>
    );
  });

  useEffect(() => {
    const sindicato = process.env.REACT_APP_NOMESINDICATO;
    document.title = `Votação Online ${sindicato}`;

    const assembleiaStored = sessionStorage.getItem('@Suffragium:assembleia');

    if (assembleiaStored) {
      const { id } = JSON.parse(assembleiaStored);
      api.get(`assembleias/${id}`).then((response) => {
        const {
          titulo,
          pergunta,
          opcoes_voto,
          perguntas: listaPerguntas,
        } = response.data;

        const options = [] as OpcaoVoto[];

        const mappedOptions = opcoes_voto.split(',');

        mappedOptions.map((opcao: string) =>
          options.push({ id: opcao.toLowerCase(), label: opcao }),
        );

        const mappedPerguntas = pergunta.split(';');
        let perguntasList: PerguntaAssembleia[] = [] as PerguntaAssembleia[];
        if (!listaPerguntas) {
          perguntasList = mappedPerguntas.map((p: string, index: number) => ({
            id: index,
            pergunta: p,
            opcoes: options,
          }));
        } else {
          perguntasList = listaPerguntas.map((p: PerguntaAssembleia) => p);
        }
        setPerguntas(perguntasList);
        setAssembleia({ id, titulo });
      });
    }
  }, [endSession]);

  const handleSubmit = useCallback(
    async ({ votos }: { votos: { voto: string }[] }) => {
      const emptyVotos = votos.filter((voto) => voto.voto === '');
      if (emptyVotos.length > 0) {
        addToast({
          title: 'Voto inválido',
          description: 'Por favor, escolha uma opção para cada pergunta',
          type: 'error',
        });
      } else {
        const bodyParameters = {
          assembleia_id: assembleia?.id,
          voto: votos.map((voto, index) => ({
            pergunta_id: index + 1,
            opcao_voto: voto.voto,
          })),
        };

        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };

        const response = await api.post('votos', bodyParameters, config);
        const votoObj = {
          id: response.data.id,
          assembleia: {
            id: response.data.assembleia_id,
          },
          empregado: {
            id: response.data.empregado_id,
          },
        };

        localStorage.setItem('@Suffragium:voto:new', JSON.stringify(votoObj));
        history.push('/assembleia/comprovantevoto');
        logoutVote();
      }
    },
    [addToast, token, assembleia, history, logoutVote],
  );

  return (
    <Template>
      <Container>
        <Information>
          <strong>{`Tempo restante para confirmar: ${count}s`}</strong>
          <h1>{assembleia?.titulo}</h1>
          <h3>
            <span>Bem vindo, </span>
            {empregado?.nome}
            <span>.</span>
          </h3>
          <h3>ASSEMBLEIA DE APRECIAÇÃO E DELIBERAÇÃO SOBRE:</h3>
          <form onSubmit={form.onSubmit(handleSubmit)}>
            {radioGroupFields}
            <Button
              rightIcon={<AiOutlineCheckSquare />}
              size="lg"
              color="green"
              type="submit"
            >
              CONFIRMAR
            </Button>
          </form>
        </Information>
      </Container>
    </Template>
  );
}

export default Validation;
