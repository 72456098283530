/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useState, useCallback, ChangeEvent } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from '@react-pdf/renderer';

import { Dimmer, Loader } from 'semantic-ui-react';

import { AiOutlinePrinter } from 'react-icons/ai';
import { format, utcToZonedTime } from 'date-fns-tz';
import { Button } from '@mantine/core';
import { useHistory } from 'react-router-dom';
import { Header, Container } from './styles';
import NavBar from '../../../components/NavBar';

import api from '../../../services/api';

import { useAuth } from '../../../hooks/auth';

interface OpcaoVoto {
  pergunta_id: number;
  opcao_voto: string;
}

interface TotalOpcaoVoto {
  pergunta_id: number;
  total_pergunta: [string, number][];
}

interface PerguntaAssembleia {
  id: number;
  opcoes: [
    {
      id: number;
      label: string;
    },
  ];
  pergunta: string;
}

interface VotoData {
  id: string;
  data_voto: string;
  voto: OpcaoVoto[];
  empregado: {
    id: number;
    nome: string;
    cpf: string;
  };
  assembleia: {
    id: string;
    titulo: string;
  };
}

interface ReportData {
  assembleia_id: string;
  assembleia_titulo: string;
  perguntas: PerguntaAssembleia[];
  votos: VotoData[];
  totalizadores: TotalOpcaoVoto[];
}

interface AssembleiaData {
  id: string;
  titulo: string;
  data_inicio: string;
  data_fim: string;
  ativa?: boolean;
  opcoes?: PerguntaAssembleia[];
  recebe_votos?: boolean;
  total_votos?: number;
}
const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 46,
    paddingHorizontal: 35,
    flexDirection: 'column',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  section: {
    marginBottom: -2,
    fontSize: 12,
  },
  title: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 'bold',
  },
  subTitle: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
  titleData: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  titleTotal: {
    marginTop: 30,
    fontWeight: 'bold',
    fontSize: 18,
  },
});

interface MyDocumentProps {
  reportData: ReportData;
  user: {
    id: number;
    nome: string;
    sindicato: string;
    sigla: string;
  };
  checked: boolean;
}

// Create Document Component
function MyDocument({
  reportData,
  user,
  checked,
}: MyDocumentProps): JSX.Element {
  const showVoteComplete = !!process.env.REACT_APP_SHOW_VOTE_COMPLETE || false;
  return (
    <Document
      title={`Relatório de Votação - ${reportData.assembleia_titulo}`}
      key={`Relatório de Votação - ${reportData.assembleia_titulo}`}
    >
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.section} fixed>
          <Text style={styles.title}>
            {`Relatório Emitido por: ${user.nome}`}
          </Text>
          <Text style={styles.title}>{`${reportData.assembleia_titulo}`}</Text>
          <Text style={styles.subTitle}>
            {`ID da Assembleia: ${reportData.assembleia_id}`}
          </Text>
        </View>

        {!checked &&
          reportData.votos.map(
            ({ id, data_voto, empregado, voto }: VotoData) => {
              return (
                <View style={styles.section} key={id} wrap={false}>
                  <Text>
                    {`\nDados do voto:      ID: ${id} - Data: ${format(
                      utcToZonedTime(data_voto, 'America/Sao_Paulo'),
                      'dd/MM/yyyy - HH:mm:ss',
                    )}`}
                  </Text>
                  <Text>Dados do Empregado:</Text>
                  <Text>{`ID: ${empregado.id}\nNome: ${empregado.nome} - CPF: ${empregado.cpf}`}</Text>
                  {showVoteComplete &&
                    voto.map((v) => {
                      return (
                        <Text key={v.pergunta_id}>
                          {`Pergunta: ${v.pergunta_id} - Opção: ${v.opcao_voto}`}
                        </Text>
                      );
                    })}
                </View>
              );
            },
          )}

        <View style={styles.section}>
          <Text style={styles.titleTotal}>TOTAL DE VOTOS DESTA ASSEMBLEIA</Text>
          {reportData.totalizadores.map((totalizador) => {
            return (
              <>
                <Text key={totalizador.pergunta_id}>
                  {`Pergunta ${totalizador.pergunta_id}: ${
                    reportData.perguntas.find(
                      (pergunta) => pergunta.id === totalizador.pergunta_id,
                    )?.pergunta
                  }`}
                </Text>
                {totalizador.total_pergunta.map((values) => {
                  const [key, value] = values;
                  return <Text>{`${key}: ${value}`}</Text>;
                })}
              </>
            );
          })}
        </View>
      </Page>
    </Document>
  );
}

function Reports() {
  const { token, user } = useAuth();
  const [renderReport, setRenderReport] = useState(false);
  const [reportData, setReportData] = useState<ReportData>({} as ReportData);

  const [assembleias, setAssembleias] = useState<AssembleiaData[]>([]);
  const [assembleiaId, setAssembleiaId] = useState('');
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const history = useHistory();

  // Create styles

  const handleCheck = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setChecked(e.target.checked);
      setRenderReport(false);
    },
    [setChecked],
  );

  const handleAPI = useCallback(async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    setRenderReport(false);
    setLoading(true);
    if (assembleiaId !== '') {
      try {
        await api
          .get(`votos/reports/all?assembleia_id=${assembleiaId}`, config)
          .then((response) => {
            const dataProcess: ReportData = {} as ReportData;

            const { assembleia: assembleiaData, totais, votos } = response.data;

            const totaisArray = Object.entries(
              totais as Record<number, Record<string, number>>,
            );
            const totalizadores = totaisArray.map(([key, valuesOpcoes]) => {
              const totaisOpcaoEntries = Object.entries(valuesOpcoes);
              return {
                pergunta_id: parseInt(key, 10),
                total_pergunta: totaisOpcaoEntries,
              } as TotalOpcaoVoto;
            });

            dataProcess.totalizadores = totalizadores;

            dataProcess.assembleia_id = assembleiaData.id;
            dataProcess.assembleia_titulo = assembleiaData.titulo;
            dataProcess.perguntas = assembleiaData.opcoes;
            dataProcess.votos = votos;
            setReportData(dataProcess);
            setLoading(false);
            setRenderReport(true);
          })
          .catch((error) => {
            throw error;
          });
      } catch (error) {
        setReportData({} as ReportData);
        setLoading(false);
      }
    }
  }, [token, assembleiaId]);

  const handlePopulateSelect = useCallback(async () => {
    await api.get('assembleias/?type=select').then((response) => {
      let assembleiasData: Array<AssembleiaData> = [] as Array<AssembleiaData>;
      response.data.forEach((assembleiaData: AssembleiaData): void => {
        const { id, titulo, data_inicio, data_fim, ativa, recebe_votos } =
          assembleiaData;

        assembleiasData = [
          ...assembleiasData,
          {
            id,
            titulo,
            data_inicio,
            data_fim,
            ativa,
            recebe_votos,
          },
        ];
      });
      setAssembleiaId(assembleiasData[0]?.id);
      setAssembleias(assembleiasData);
    });
  }, []);

  const handleSelectChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      event.preventDefault();
      const { value } = event.target;
      if (value) {
        setAssembleiaId(value);
        setRenderReport(false);
      }
    },
    [],
  );

  useEffect(() => {
    const sindicato = process.env.REACT_APP_NOMESINDICATO;
    document.title = `Votação Online ${sindicato}`;
    if (!user) {
      history.push('/admin');
    }
    handlePopulateSelect();
  }, [handlePopulateSelect, history, user]);

  return (
    <>
      <Header>
        <img src={process.env.REACT_APP_LOGOAPP} alt="logo_app" />
        <img src={process.env.REACT_APP_LOGOSINDICATO} alt="logo_sindicato" />
      </Header>
      <NavBar />
      <Container>
        <strong>
          {'Selecione a Assembleia e em seguida clique em Gerar Relatório. '}
        </strong>
        <select
          name="assembleiasList"
          id="assembleiasList"
          value={assembleiaId}
          onChange={handleSelectChange}
        >
          {assembleias.map((option, index) => {
            return (
              <option value={option.id} key={option.id} selected={index === 0}>
                {option.titulo}
              </option>
            );
          })}
        </select>

        <Dimmer active={loading}>
          <Loader indeterminate>Gerando Relatório. Aguarde...</Loader>
        </Dimmer>
        <label htmlFor="termo">
          <input
            type="checkbox"
            name="termo"
            id="termo"
            checked={checked}
            onChange={handleCheck}
          />
          <strong>Apenas totais</strong>
        </label>
        <Button
          leftIcon={<AiOutlinePrinter />}
          type="button"
          onClick={handleAPI}
        >
          Gerar Relatório
        </Button>

        {renderReport && (
          <PDFViewer>
            <MyDocument reportData={reportData} user={user} checked={checked} />
          </PDFViewer>
        )}
      </Container>
    </>
  );
}

export default Reports;
